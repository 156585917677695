<template>
    <div>
      <div v-if="customData.values.length === 0">
        <label>
          {{ emptyListLabel }}
        </label>
      </div>
      <div v-else>
        <div class="overflow-hidden">
          <div class="mt-1 d-flex flex-row flex-wrap">
            <div
                v-for="(tag, i) of customData.values"
            >
               <span
                 :key="'tag-customer' + i"
                 class="custom-tag tag-light-blue mt-1 mr-1"
               >
                 {{ tag.label }}
               </span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  export default {
    props: ['customData'],
    computed: {
      emptyListLabel () {
        switch (this.customData.key) {
          case 'customers':
            return this.$t("views.marketing.report.resume-content.sent-list-table.all-customers");
          default:
            return '-';
        }
      }
    },
  }
</script>
<style lang="scss" src="@lazy/_custom-tag.scss" scoped/>

